<template>
  <v-card>
    <v-container>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="generarComprobante()"
      >
        <v-data-table
          :headers="headers"
          :items="itemsGenerarComprobante"
          class="elevation-1"
          item-key="fecha"
          :loading="loading"
        >
          <template v-slot:[`item.agectaId`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.agectaId }}
            </span>
          </template>
          <template v-slot:[`item.agectaNom`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.agectaNom }}
            </span>
          </template>
          <template v-slot:[`item.osNom`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.osNom }}
            </span>
          </template>
          <template v-slot:[`item.tCompNom`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.tCompNom }}
            </span>
          </template>
          <template v-slot:[`item.coseCtaCteSuc`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.coseCtaCteSuc }}
            </span>
          </template>
          <template v-slot:[`item.numeroOrden`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.numeroOrden }}
            </span>
          </template>
          <template v-slot:[`item.coseTotalString`]="{ item }">
            <span
              :style="
                item.tCompNom == '' || item.coseCtaCteSuc == null
                  ? 'color:red'
                  : ''
              "
            >
              {{ item.coseTotalString }}
            </span>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row class="pb-0">
                <v-col cols="12" align="start">
                  <v-card-title class="pl-1 primary--text"
                    >{{ title }}
                  </v-card-title>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-row>
              <v-col cols="4" md="4" class="pb-0">
                <!-- fecha emisión -->
                <v-menu
                  ref="menu"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaEmision"
                      label="Fecha de emisión"
                      :append-icon="calendarIcon"
                      v-mask="'##/##/####'"
                      hint="formato DD/MM/AAAA"
                      @keydown.tab="menu1 = false"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      @blur="[(fechaEmi = parseDateToIso(fechaEmision))]"
                      @change="[(fechaEmi = parseDateToIso(fechaEmision))]"
                      clearable
                      dense
                      outlined
                      autocomplete="off"
                      :rules="
                        rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(fechaEmision)) <=
                            new Date(parseDateToIso(fechaVencimiento)) ||
                            'Formato incorrecto'
                        )
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="fechaEmi"
                    no-title
                    scrollable
                    @change="[(fechaEmision = formatDate(fechaEmi))]"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" md="4" class="pb-0">
                <!-- fecha vencimiento -->

                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fechaVencimiento"
                      label="Fecha de vto."
                      :append-icon="calendarIcon"
                      @keydown.tab="menu2 = false"
                      v-mask="'##/##/####'"
                      onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                      hint="formato DD/MM/AAAA"
                      clearable
                      dense
                      @blur="[(fechaVenc = parseDateToIso(fechaVencimiento))]"
                      @change="[(fechaVenc = parseDateToIso(fechaVencimiento))]"
                      :rules="
                        rules.required.concat(
                          rules.validDate,
                          new Date(parseDateToIso(fechaEmision)) <=
                            new Date(parseDateToIso(fechaVencimiento)) ||
                            'Formato incorrecto'
                        )
                      "
                      outlined
                      autocomplete="off"
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fechaVenc"
                    no-title
                    scrollable
                    @change="[(fechaVencimiento = formatDate(fechaVenc))]"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- período -->
              <v-col cols="4" md="4" class="pb-0 ">
                <v-text-field
                  ref="periodoD"
                  v-model="periodo"
                  label="Período"
                  outlined
                  dense
                  clearable
                  autocomplete="not"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  :rules="rules.required.concat(rules.periodoYyyyMm)"
                  hint="Formato AAAAMM"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalGenerarComprobante()"> Cerrar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingGenerarComprobante"
          form="form"
          color="primary"
        >
          Generar comprobante
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mask } from "vue-the-mask";

export default {
  components: { PageHeader, DeleteDialog },
  name: "GenerarComprobanteCtaCte",
  directives: { mask },
  props: {
    registrosSeleccionados: {
      type: Array,
      required: true
    },
    fechaDesde: {
      type: String,
      required: true
    },
    fechaHasta: {
      type: String,
      required: true
    },
    idAgente: {
      type: Number
    }
  },
  data() {
    return {
      calendarIcon: enums.icons.CALENDAR,
      title: "Generación de comprobante",
      fechaEmision: null,
      fechaVencimiento: null,
      fechaEmi: null,
      fechaVenc: null,
      periodo: null,
      menu1: false,
      menu2: false,
      itemsGenerarComprobante: [],
      isFormValid: false,
      rules: rules,
      headers: [
        {
          text: "N° Agente de cuenta",
          value: "agectaId",
          sortable: false
        },
        {
          text: "Agente de cuenta",
          value: "agectaNom",
          sortable: false
        },
        {
          text: "Obra social",
          value: "osNom",
          sortable: false
        },
        {
          text: "Tipo de comprobante",
          value: "tCompNom",
          sortable: false
        },
        {
          text: "Sucursal",
          value: "coseCtaCteSuc",
          sortable: false
        },
        {
          text: "Importe",
          value: "coseTotalString",
          sortable: false,
          align: "end"
        }
      ],
      loading: false,
      loadingGenerarComprobante: false,
    };
  },
  created() {
    const traerFecha = this.hoyFecha();
    this.fechaEmision = this.hoyFecha();
    this.fechaVencimiento = traerFecha;
    this.loadGenerarComprobante();
    this.periodo = this.traerPeriodo()
  },
  methods: {
    ...mapActions({
      agrupaEncautoComprobantesCtaCte:
        "configuracion/agrupaEncautoComprobantesCtaCte",
      grabaProcesoComprobantesCtaCte:
        "configuracion/grabaProcesoComprobantesCtaCte",
      setAlert: "user/setAlert"
    }),
    hoyFecha() {
      let now = new Date();
      let day = ("0" + now.getDate()).slice(-2);
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let today = day + "/" + month + "/" + now.getFullYear();
      return today;
    },
    traerPeriodo() {
      const anioMesDia = this.parseDateToIso(this.fechaEmision);
      const anioMes = anioMesDia.substring(0, 7);
      return anioMes.replace("-", "");
    },

    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        //return `${month}${year} = date.split("/");
        const dat = new Date();
        dat
          .toLocaleDateString("en-GB")
          .split("/")
          .reverse()
          .join("");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeModalGenerarComprobante() {
      this.$emit("closeModalGenerarComprobante");
    },
    closeModalGenerarComprobanteAndReload() {
      this.$emit("closeModalGenerarComprobanteAndReload");
    },
    async loadGenerarComprobante() {
      this.loading = true;
      const respo = await this.agrupaEncautoComprobantesCtaCte(
        this.registrosSeleccionados
      );
      this.itemsGenerarComprobante = respo;
      this.loading = false;
    },
    async generarComprobante() {
      this.loadingGenerarComprobante = true;
      const data = {
        comprobantesAGenerar: this.itemsGenerarComprobante,
        parametrosGeneraComprobante: {
          agectaId: this.idAgente != null ? this.idAgente : 0,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          fechaEmision: this.fechaEmision,
          fechaVencimiento: this.fechaVencimiento,
          periodo: this.periodo
        }
      };
      const res = await this.grabaProcesoComprobantesCtaCte(data);
      let errores = res.find(item => item.idError > 0);
      if (!errores)
        this.setAlert({
          type: "success",
          message: "El comprobante de cuenta corriente se ha generado con éxito"
        });
      else {
        this.setAlert({
          type: "warning",
          message: errores.mensaje
        });
      }
      this.closeModalGenerarComprobanteAndReload();
      this.$router.push({ name: "DevengamientoCosegurosWeb" });
      this.loadingGenerarComprobante = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
